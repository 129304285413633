import axios from "./axios.js"

const http = {
	//登录
	login: function (body) {
		return axios('post', '/shop/auth/web/login', body)
	},
	//获取验证码
	getCode: function (body) {
		return axios('post', '/sms/send-captcha', body) //post方法以及传参    qwe是字段   e是参数
	},
	//注册
	register: function (body) {
		return axios('post', '/shop/auth/web/register', body)
	},

	//判断是否注册过手机号
	is_register: function (body) {
		return axios('get', '/shop/auth/web/judge-is-registered', body)
	},
	//忘记密码
	forget: function (body) {

		return axios('post', '/shop/auth/web/forget-password', body)
	},
	// 修改手机号码
	changePhone: function (body) {

		return axios('post', '/shop/web/user/change-phone', body)
	},
	// 修改登录密码
	changePass: function (body) {
		console.log('body---', body)

		return axios('post', '/shop/auth/web/forget-password', body)
	},

	// ---------------
	// 首页商品列表
	shopList: function (body) {
		return axios('get', '/shop/web/goods_base_info/list', body)
	},
	// 商品分类
	shopType: function (body) {
		return axios('get', '/shop/web/shop_category_info/select', body)
	},

	// ----------------
	// 商品详情
	ProductDetails: function (body) {
		return axios('get', '/shop/web/goods_base_info/detail', body)
	},
	// 地址列表   下单 --选择地址的列表
	ChooseAddressList: function (body) {
		return axios('get', '/shop/web/shop_address_tmplate_info/select', body)
	},

	// 商品评价列表
	getProductEvaluationList: function (body) {
		return axios('get', '/shop/web/shop_goods_order_evaluate_info/list', body)
	},
	// 查询库存价格	
	getProductMoney: function (body) {
		return axios('post', '/shop/web/goods_base_info/spec_info', body)
	},

	// 确认下单
	sureOrder: function (body) {
		return axios('post', '/shop/web/shop_goods_order_info/store', body)
	},
	// 建立链接，获取支付成功数据
	bindWebSocket: function (body) {
		return axios('post', '/shop/web/ws/bind', body)
	},
	// 根据规格数量获取价格
	getPricebySpecSku: function (body) {
		if (body.id) {
			return axios('post', '/shop/web/goods_base_info/price_by_spec_sku?id=' + body.id || '', body)

		} else {
			return axios('post', '/shop/web/goods_base_info/price_by_spec_sku', body)

		}
	},

	// --------------------
	// 个人中心
	// 地址管理 地址列表
	AddressList: function (body) {
		return axios('get', '/shop/web/shop_address_tmplate_info/list', body)
	},
	// 地址管理 地址详情
	AddressDetail: function (header) {
		return axios('get', '/shop/web/shop_address_tmplate_info/detail?id=' + header)
	},

	// 地址管理 增加地址
	addAddress: function (body) {
		return axios('post', '/shop/web/shop_address_tmplate_info/store', body)
	},
	// 地址管理 修改地址
	changeAddress: function (header, body) {
		return axios('post', '/shop/web/shop_address_tmplate_info/update?id=' + header, body)
	},
	// 地址管理 设置默认地址
	setAddress: function (body) {
		return axios('post', '/shop/web/shop_address_tmplate_info/default?id=' + body.id)
	},
	// 地址管理 删除地址
	deleteAddress: function (body) {
		return axios('post', '/shop/web/shop_address_tmplate_info/delete', body)
	},
	// 省市区列表
	ProvinceCityDistrict: function (body) {
		return axios('get', '/region/list', body)
	},

	// 发票管理 发票列表
	billList: function (body) {
		return axios('get', '/shop/web/invoice_template/list', body)
	},
	// 发票管理 发票详情
	billDetail: function (body) {
		return axios('get', '/shop/web/invoice_template/detail', body)
	},
	// 发票管理 新增发票
	addBill: function (body) {
		return axios('post', '/shop/web/invoice_template/store', body)
	},
	// 发票管理 修改发票
	changeBill: function (header, body) {
		return axios('post', '/shop/web/invoice_template/update?id=' + header, body)
	},
	// 发票管理 设置默认发票
	setBill: function (body) {
		return axios('post', '/shop/web/invoice_template/default?id=' + body.id)
	},
	// 发票管理 删除发票
	deleteBill: function (body) {
		return axios('post', '/shop/web/invoice_template/delete', body)
	},

	// ------------我的订单---------------
	//  获取订单列表
	getOrderList: function (body) {
		return axios('get', '/shop/web/shop_goods_order_info/list', body)
	},
	//  获取订单详情
	getOrderDetail: function (body) {
		return axios('get', '/shop/web/shop_goods_order_info/detail', body)
	},


	//  添加订单备注
	setRemark: function (header, body) {
		return axios('post', '/shop/web/shop_goods_order_info/set_remark?id=' + header, body)
	},
	//  联系商家，查看商家联系方式
	shopDetail: function (header) {
		return axios('get', '/shop/web/shop_base_info/detail?id=' + header)
	},
	// 物流信息
	expressDetail: function (header) {
		return axios('get', '/shop/web/shop_goods_order_info/transport?id=' + header)
	},

	// 取消订单
	cancelorder: function (header, body) {
		return axios('post', '/shop/web/shop_goods_order_info/cancel?id=' + header, body)
	},
	// 取消信息
	canceldetail: function (header) {
		return axios('get', '/shop/web/shop_goods_order_info/cancel_info?id=' + header)
	},
	// 确认收货
	confirmReceipt: function (header) {
		return axios('post', '/shop/web/shop_goods_order_info/confirm_order?id=' + header)
	},
	// 收货信息
	ReceiptDetail: function (body) {
		return axios('get', '/shop/web/shop_receive_address_info/detail_by_orderid', body)
	},
	// 发票详情
	shopInvoiceInfo: function (header) {
		return axios('get', '/shop/web/shop_invoice_info/detail?id=' + header)
	},
	// 申请退款
	addRefund: function (header, body) {
		return axios('post', '/shop/web/shop_goods_order_info/refund?id=' + header, body)
	},

	// 添加评论
	addEvaluate: function (header, body) {
		return axios('post', '/shop/web/shop_goods_order_evaluate_info/store?id=' + header, body)
	},
	// 退款订单列表
	getRefundOrderList: function (body) {
		return axios('get', '/shop/web/shop_goods_order_refund_info/list', body)
	},

	// 取消退款
	cancelRefund: function (header) {
		return axios('post', '/shop/web/shop_goods_order_refund_info/cancel?id=' + header)
	},

	// 评价内容
	evaluateDetail: function (body) {
		return axios('get', '/shop/web/shop_goods_order_evaluate_info/detail', body)
	},

	// 退款详情
	refundDetail: function (body) {
		return axios('get', '/shop/web/shop_goods_order_refund_info/detail', body)
	},

	// 开票信息
	getInvoice: function (body) {
		return axios('get', '/shop/web/shop_invoice_info/get_invoice', body)
	},

	// 新增开票信息
	orderaddInvoice: function (body) {
		return axios('post', '/shop/web/shop_invoice_info/store', body)
	},

	//获取系统配置
	webConfigDetail: function () {
		return axios('get', '/shop/web/config/detail?group=mall')
	},
	//获取系统配置
	webConfigDetail: function () {
		return axios('get', '/shop/web/config/detail?group=mall')
	},
	//获取商家详情
	MerchantDetail: function (body) {
		return axios('get', '/shop/web/shop_base_info/detail?id=' + body.id)
	},

}
Vue.prototype.http = http;
export default http
