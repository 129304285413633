import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: 'index',
		meta: {
			title: '首页'
		},
		component: () => import('@/pages/index.vue')
	},

	{
		path: '/myOrder',
		name: 'myOrder',
		meta: {
			title: '我的订单'
		},
		component: () => import('@/pages/myOrder.vue')
	},

	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录注册'
		},
		component: () => import('@/pages/login.vue')
	},

	{
		path: '/ProductDetails',
		name: 'ProductDetails',
		meta: {
			title: '商品详情'
		},
		component: () => import('@/pages/ProductDetails.vue')
	},
	{
		path: '/ConfirmOrder',
		name: 'ConfirmOrder',
		meta: {
			title: '下单'
		},
		component: () => import('@/pages/ConfirmOrder.vue')
	},

	{
		path: '/personalCenter',
		name: 'personalCenter',
		meta: {
			title: '个人中心'
		},
		component: () => import('@/pages/personalCenter.vue')
	},
	{
		path: '/refundOrder',
		name: 'refundOrder',
		meta: {
			title: '退款订单'
		},
		component: () => import('@/pages/refundOrder.vue')
	},
	{
		path: '/Merchant',
		name: 'Merchant',
		meta: {
			title: '店铺详情'
		},
		component: () => import('@/pages/Merchant.vue')
	},

	{
		path: '/licence',
		name: 'licence',
		meta: {
			title: '增值电信业务经营许可证'
		},
		component: () => import('@/pages/licence.vue')
	},

]


const router = new VueRouter({
	routes,
	mode: 'history'
})

// 添加如下代码可以防止重复点击一个路径是浏览器报路径重复的错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
	document.title = to.meta.title
	next()
})
export default router
