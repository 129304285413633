<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <myfooter v-if="isShow" />
  </div>
</template>
<script>
import myheader from "@/components/myheader";
import myfooter from "@/components/myfooter";
export default {
  name: "App",
  components: {
    myheader,
    myfooter
  },
  data() {
    return {
      isShow: true
    };
  },
  computed: {
    newRouter() {
      return this.$route.name;
    }
  },

  watch: {
    $route: function () {
      console.log("this.$route.name--",this.$route.name)
      if (this.$route.name === "login"||this.$route.name === "licence") {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    newRouter: {
      handler: () => {
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>
<style>
body {
  /* width: 100%; */
  /* height: 100%; */
  background: #f4f4f4;
  /* display: flex;
	flex-direction: column;
	align-items: center; */
}

div {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

tr td {
  border: node;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

/* 谷歌滚动条 */
::-webkit-scrollbar-thumb:horizontal {
  /*水平滚动条的样式*/
  width: 10px;
  background-color: #cccccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
  border-radius: 3px;
  /*滚动条的背景颜色*/
  -webkit-border-radius: 3px;
  /*滚动条的圆角宽度*/
}

::-webkit-scrollbar {
  width: 13px;
  /*滚动条的宽度*/
  height: 8px;
  /*滚动条的高度*/
  -webkit-transition: all 2s linear;
  transition: all 2s linear;
}

::-webkit-scrollbar-thumb:vertical {
  /*垂直滚动条的样式*/
  height: 50px;
  background-color: #cacaca;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  /*滚动条的hover样式*/
  height: 50px;
  border-radius: 14px;
  background-color: #9f9f9f;
  -webkit-border-radius: 14px;
}

/* 浮动 */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.overflow {
  overflow: hidden;
}

.pagebody {
  width: 1200px;
  margin: auto;
  margin-top: 150px;
  background: #fff;
}

.TextOverflow {
  overflow: hidden;
  /* //超出的文本隐藏 */
  text-overflow: ellipsis;
  /* //溢出用省略号显示 */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 超出多少行 */
  -webkit-box-orient: vertical;
}

.TextOverflowline3 {
  overflow: hidden;
  /* //超出的文本隐藏 */
  text-overflow: ellipsis;
  /* //溢出用省略号显示 */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* 超出多少行 */
  -webkit-box-orient: vertical;
}

.TextOverflowline4 {
  overflow: hidden;
  /* //超出的文本隐藏 */
  text-overflow: ellipsis;
  /* //溢出用省略号显示 */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* 超出多少行 */
  -webkit-box-orient: vertical;
}

.cursor {
  cursor: pointer;
}

/* 文字链接效果 */
.btn_hover_text_color:hover {
  color: #ee0707 !important;
}

/* 红色实心按钮效果 */
.solid_btn_hover_background:hover {
  background: #c40101 !important;
  color: #fff !important;
  border: none !important;
}

/* 红色空心按钮效果 */
.shollow_btn_hover_background:hover {
  background: #ee0707 !important;
  color: #fff !important;
  border: none !important;
}

/* 白色背景灰色边框按钮效果 */
.white_background_border:hover {
  background: #e5e5e5 !important;
  border: none !important;
}

/* 联系卖家文字效果 */
.Contact_Seller_text:hover {
  color: #cb2f00 !important;
}

/* 文字链接效果， 有下划线 */
.btn_hover_text_color_line:hover {
  text-decoration: underline;
  color: #ee0707 !important;
}
</style>
